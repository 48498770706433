footer {
    background: var(--fondo);
    padding-top: 65px;
    padding-bottom: 35px;
    border-top: 1px solid #dee2e6;
    margin-top: 80px;
    opacity: 0;
    animation: 1s fadein 1s forwards;
}

footer .container.full-width {
    padding: 5rem 30px 15px 30px;
    margin: 0;
}

footer .menu-footer a {
    display: block;
    padding: 0.5rem 1rem;
    color: #343a40;
    padding-bottom: 2.5rem;
    padding-left: 0.5rem;
    font-size: 15px;
    letter-spacing: 2px;
    font-family: Circular;
    font-weight: 700;
    margin-right: auto;
}

footer .menu-footer a:hover {
    color: #121416;
}

footer .row:nth-of-type(2) {
    margin-top: 50px;
    color: #6c757d;
    font-size: 12px;
    letter-spacing: 2px;
    font-family: Circular;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
footer .row:nth-of-type(2) a,
footer .row:nth-of-type(2) a:hover {
    color: #6c757d;
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #dee2e6;
}

footer .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.home footer,
.planear-evento footer {
    margin-top: 0;
    border-top: none;
}

footer .logo {
    width: 195px;
    height: auto;
}

@media (max-width: 991px) {
    .footer-links {
        font-size: 10px;
    }

    body footer .logo {
        margin-bottom: calc(1em + 30px);
    }

    footer .row:nth-of-type(2) {
        margin-top: 20px;
    }

    footer .row:nth-of-type(2) .links a:nth-of-type(1) {
        padding-left: 0;
        margin-left: 0;
        border-left: none;
    }
}
