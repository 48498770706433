.heading-ficha-evento {
    position: relative;
}

.evento {
    padding-top: 40px;
    transition: opacity 300ms;
}

.event-cover {
    width: 100%;
    height: 456px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
    position: relative;
    max-width: 1140px;
    margin: 0 auto;
}

.event-cover .toggle-favorite {
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: white;
    cursor: pointer;
    transform: scale(1);
}

.event-cover .toggle-favorite i {
    font-size: 23px;
    transition: 100ms;
    transform: scale(1);
}

.event-cover .toggle-favorite.favorited i {
    transform: scale(1.1);
    animation-name: favorited;
    animation-delay: 150ms;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
}

@keyframes favorited {
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.event-info h1 {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 10px;
    font-family: Circular;
    font-size: 30px;
    line-height: 24px;
    margin: 0;
}

.event-info h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    color: #4f4f65;
    text-transform: uppercase;
    margin-bottom: 1em;
}

.event-description,
.event-menu {
    font-size: 16px;
    font-family: Circular;
    font-weight: 400;
    line-height: 24px;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
}

.event-description {
    border-bottom: 1px solid lightgrey;
    padding-top: 20px;
}

.event-description p:last-child {
    margin-bottom: 0;
}

.evento .date {
    margin-bottom: 25px;
    box-shadow: 0px 4px 8px 0px rgba(27, 28, 36, 0.08);
    background: white;
    border-radius: 20px;
    opacity: 0;
    animation-name: carousel-item-container;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    border: 1px solid transparent;
    border-width: 1px 1px 5px 1px;
    transition: 300ms;
    cursor: pointer;
    position: relative;
}


.evento .date .more-info {
    float: right;
    margin: -4px -8px 0px auto;
}

.evento .date .event-type {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
    background: var(--resaltado);
    border-radius: 16px;
    font-size: 12px;
	font-weight: 600;
    padding: 2px 10px;
    text-transform: uppercase;
    color: white;
}

.evento .date .event-type.no-calendar {
    top: unset;
    bottom: 12px;
}

.evento .date.out-of-stock {
    cursor: default;
}

.evento .date .event-type.out-of-stock {
    background-color: #ea5555;
}

.evento .col-dates {
    margin-left: auto;
    padding-right: 0;
}

.evento .col-dates.floating {
    position: absolute;
}

.evento .date .event-info-line {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: grey;
    background: white;
    border-radius: 0 0 5px 5px;
}

.evento .date .event-info-tag {
    border: 2px solid var(--resaltado-bordes);
    background: white;
    color: var(--gris-texto);
    border-radius: 20px;
    font-weight: 100;
    font-size: 11px;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
    padding: 5px 15px;
    margin-left: -2px;
}

.evento .date .event-info-tag.not-renewable {
    border-color: #ee666677;
    color: #ea5555;
}

.evento .date .event-info-image {
    height: 150px;
    border-radius: 20px 20px 0 0; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.evento .date .event-info-name {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
}

.evento .date .details {
    padding: 10px 15px 10px 15px;
}

.evento .date .event-info-pax {
    position: absolute;
    top: 0;
    right: 10px;
    text-transform: uppercase;
    background: var(--resaltado);
    color: white;
    padding: 5px 13px;
    font-weight: bold;
    border-radius: 20px;
    font-size: 12px;
    display: inline-block;
    margin: 10px 0;
    box-shadow: 0 0 2px #00000077;
}

.tags + .info-negocio .event-text {
    margin-top: 18px;
}

.info-negocio .event-text p:last-child {
    margin-bottom: 0;
}

.info-negocio .event-text.event-info {
    border-bottom: 1px solid #d3d3d8;
    margin: 0;
    padding-bottom: 20px;
    font-size: 15px;
}

.info-negocio .event-text.event-info > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.info-negocio .widget-horario {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 20px;
    margin-bottom: 15px;
}

.info-negocio .event-text.event-info .composite-component {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    border: none;
}

.info-negocio .event-text.event-info > div img {
    margin-right: 0.5em;
}

.info-negocio .event-info-line .icon-svg {
    margin-right: 5px;
}

.info-negocio .event-info-line .icon-svg-container {
    margin-left: 5px;
}

.heading-ficha-evento .row {
    margin-left: 0;
    margin-right: 0;
}

.heading-ficha-evento .icon-container {
    position: relative;
}

.heading-ficha-evento .icon-container {
    display: inline-flex;
    margin-right: 10px;
}

.heading-ficha-evento .iconos {
    max-width: 1070px;
    margin: 0 auto;
    height: 67px;
    margin-top: -67px;
    position: relative;
    z-index: 2;
}

.heading-ficha-evento .icon-image {
    width: 42px;
    height: 42px;
}

.event-description .icon-image {
    width: 48px;
    height: 48px;
    margin-right: 1.5em;
}

@media(max-width: 992px) {
    .heading-ficha-evento .iconos {
        position: absolute;
        top: 10px;
        left: 0;
        margin-top: 0;
        max-width: 930px;
    }

    .heading-ficha-evento .icon-container {
        display: inline-flex;
        margin-left: 10px;
        margin-right: 0;
    }

    .heading-ficha-evento .icon-image {
        width: 36px;
        height: 36px;
    }
}

.experiencias {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    cursor: default;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 23px;
    margin-top: 23px;
    padding-bottom: 8px;
}

.subscriptions-enabled .experiencias {
    border-bottom: 0;
}

.evento .event-dates-responsive .date.mas-info,
.evento .experiencias .date {
    cursor: default;
    background: var(--resaltado-claro);
    box-shadow: none;
    border-width: 1px;
    margin-bottom: 15px;
}

.evento .event-dates-responsive .date.mas-info .read-more-link {
    position: absolute;
    right: 17px;
    top: 16px;
    padding: 6px 15px;
    font-size: 11px;
    border: 1px solid var(--gris-texto);
    text-transform: uppercase;
    color: var(--gris-texto);
    font-weight: 600;
}

.evento .event-dates-responsive .date.mas-info .details,
.evento .experiencias .date .details {
    padding: 20px 25px;
}

.evento .event-dates-responsive .date.mas-info .event-info-name,
.evento .experiencias .date .event-info-name {
    margin-bottom: 25px;
}

.evento .event-dates-responsive .date.mas-info .event-info-name {
    max-width: 73%;
}

.subscriptions-enabled .evento .experiencias .date .event-info-name {
    margin-bottom: 24px;
}

.evento .event-dates-responsive .date.mas-info .event-info-description,
.evento .experiencias .date .event-info-description {
    font-size: 0.9em;
    color: var(--gris-texto);
}

.evento .experiencias .date .event-info-description .read-more-link {
    position: absolute;
    right: 17px;
    top: 16px;
    padding: 6px 15px;
    font-size: 13px;
    border: 1px solid var(--gris-texto);
    text-transform: uppercase;
    color: var(--gris-texto);
    font-weight: 600;
}

.event-info-modal .modal-content-area {
    padding: 15px;
}

.evento .event-dates-responsive .date.mas-info .event-info-description p:last-child,
.evento .experiencias .date .event-info-description p:last-child {
    margin-bottom: 0;
}

.evento .event-dates-responsive .date.mas-info:hover,
.evento .experiencias .date:hover {
    box-shadow: none;
    border-color: #f0f0f0;
}

.evento .facts {
    margin-bottom: 20px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
}

.evento .experiencias + .facts {
    padding-top: 20px;
}

.subscriptions-enabled .evento .facts {
    border-bottom: 0;
}

.evento .facts .icon-image {
    width: 50px;
    height: 50px;
    margin-right: 1.5em;
	background-size: contain;
}

.evento .facts > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    font-family: Circular;
    font-size: 18px;
    font-weight: 500;
    color: #5c5c5c;
}

.evento .facts > div:first-child {
    margin-top: 15px;
}

.evento .facts > div:last-child {
	margin-bottom: 0;
}

.evento .facts p:last-child {
	margin-bottom: 0;
}

.evento .facts > div > div {
    display: flex;
    flex-direction: column;
    font-size: 17px;
}

.event-menu {
    border-bottom: 1px solid grey;
    padding-bottom: 30px;
    margin-bottom: 40px;
}

.evento .encuestas-clientes h3 {
	font-family: Circular;
    font-weight: 700;
    font-size: 27px;
    line-height: 24px;
    margin-bottom: 25px;
}

.evento .event-info .encuestas-clientes h2 {
	font-family: Circular;
    font-weight: 700;
    font-size: 17px;
    line-height: 14px;
    margin-bottom: 15px;
    color: black;
    text-transform: none;
}

.evento .encuestas-clientes {
	padding-top: 20px;
	margin-bottom: 27px;
	border-bottom: 1px solid #d3d3d8;
	padding-bottom: 27px;
}

.evento .encuestas-clientes .columna {
	display: grid;
	grid-template-columns: repeat(3, 3fr);
	gap: 20px;
}

.evento .encuestas-clientes .columna h2 {
	margin-bottom: 0.5em;
}

.evento .encuestas-clientes .category {
    background: #f6f6f6;
    padding: 15px 20px;
}

.evento .encuestas-clientes .star-meter span {
	font-size: 2.3em;
}

.related-events {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    gap: 25px;
    margin-bottom: 150px;
}

.related-events.desktop-only {
    padding-bottom: 50px;
}

.related-events-h3 {
    font-family: Circular;
    font-weight: 700;
    font-size: 27px;
    line-height: 24px;
    margin-bottom: 25px;
}

.related-events:not(.mobile-only) > div {
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px 0px rgba(27, 28, 36, 0.08);
    border-radius: 30px;
}

.related-events .event-image {
    display: block;
    height: 124px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: beige;
    border-radius: 20px;
    margin-bottom: 10px;
    filter: grayscale(1);
    transition: 300ms;
}

.related-events > div:hover .event-image {
    filter: grayscale(0);
}

.related-events .horizontal-scroll-container > div {
    box-shadow: 0 0 15px rgba(0,0,0,.1);
}

.related-events .event-name {
    font-family: Circular, sans-serif;
    font-size: 17px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 5px;
}

.related-events .event-city {
    font-family: Circular, sans-serif;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: grey;
    margin-bottom: 5px;
    margin-left: 5px;
}

.event-address {
    font-size: 1.1em;
    font-weight: 400;
    margin: 0 0 15px;
    color: #5c5c5c;
    font-family: Circular, sans-serif;
}

.event-address .address-name {
    font-weight: bold;
    font-size: 1.2em;
}

.event-info .tags {
    display: flex;
    align-items: center;
}

.event-info .tags span {
    margin-right: 0.5em;
    background: var(--resaltado);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Circular;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 13px;
    color: white;
    border-radius: 20px;
    text-transform: uppercase;
}

.other-dates.hide-nav {
    margin-bottom: -50px;
}

.other-dates .carousel-image-container {
    height: 100px;
}

.other-dates .carousel-container {
    height: 350px;
    padding-top: 25px;
}

.other-dates .item-container {
    padding-bottom: 10px;
}

.other-dates .carousel-highlight {
    font-size: 11px;
}

.other-dates .carousel-title {
    font-size: 15px;
    padding-left: 8px;
}

.other-dates .carousel-date,
.other-dates .carousel-time,
.other-dates .carousel-price {
    font-size: 13px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    margin-bottom: 1px;
}

.other-dates .row > div {
    padding-left: 0;
    padding-right: 0;
}

.other-dates:hover .carousel-nav-button {
    opacity: 1;
}

.evento .more-dates-icon-container {
    position: relative;
}

.evento .more-dates-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
}

.calendario-evento .month {
    min-height: 320px;
}

.modal-calendario .slots {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 25px;
}

.modal-calendario .slot-row {
    display: flex;
    align-items: center;
    margin: 5px 25px;
    font-family: Circular, sans-serif;
    font-size: 16px;
    border: 1px solid lightgrey;
    color: var(--gris-texto);
    border-radius: 3px;
    cursor: pointer;
    transition: 300ms;
    padding: 15px;
    user-select: none;
}

.modal-calendario .slot-row.disabled {
    cursor: default;
    opacity: 0.5;
}

.modal-calendario .slot-row:not(.disabled):hover,
.modal-calendario .slots:not(:hover) .slot-row:not(.disabled).selected {
    background-color: var(--gris-texto);
    color: white;
}

.modal-calendario .slot-time {
    margin-right: 1em;
}

.modal-calendario .available-pax {
    background: var(--resaltado);
    color: white;
    border-radius: 15px;
    text-transform: uppercase;
    padding: 2px 10px;
    font-size: 13px;
    font-weight: bold;
}

.modal-calendario .slot-amount {
    margin-left: auto;
}

.modal-calendario .top-bar {
    display: flex;
    align-items: center;
    margin: 25px 25px 0 25px;
}

.modal-calendario .selection-length-selector,
.modal-calendario .pax-selector {
    display: flex;
    user-select: none;
    border-radius: 3px 0 0 3px;
    padding: 0 25px;
    align-items: center;
}

.modal-calendario .pax-alert {
    border: 1px solid lightpink;
    color: crimson;
    padding: 5px;
    text-align: center;
    margin: 15px 25px;
    font-size: 13px;
    border-radius: 3px;
}

.modal-calendario .selection-length-selector .selection-length,
.modal-calendario .pax-selector .pax {
    flex: 1;
}

.modal-calendario .selection-length-selector,
.modal-calendario .pax-selector,
.modal-calendario .date-display {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgrey;
    height: 75px;
    box-sizing: border-box;
}

.modal-calendario .selection-length-selector {
    border-left: none;
    border-radius: 0;
}

.modal-calendario .date-display {
    border-left: none;
    border-radius: 0 3px 3px 0;
}

.modal-calendario .selection-length-selector .selection-length-button,
.modal-calendario .pax-selector .pax-button {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.modal-calendario .selection-length-selector .selection-length-button img,
.modal-calendario .pax-selector .pax-button img {
    cursor: pointer;
}

.modal-calendario .selection-length-selector .selection-length-button .icon-svg-container,
.modal-calendario .pax-selector .pax-button .icon-svg-container {
    margin-right: 0 !important;
}

.modal-calendario .selection-length-selector .selection-length-button:hover,
.modal-calendario .pax-selector .pax-button:hover {
    background: #f6f6f6;
}

.no-calendar-modal .image {
	height: min(300px, 50vh);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.no-calendar-modal .description {
	padding: 2em;
	border-bottom: 1px solid lightgrey;
}

.no-calendar-modal .quantity-selector {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 2em;
	padding-left: calc(70px - 2em);
}

.no-calendar-modal .quantity-selector .quantity-button {
	font-size: 28px;
	padding: 5px;
	border-radius: 17px;
	box-sizing: border-box;
	background-color: transparent;
}

.no-calendar-modal .quantity-selector .quantity-button:hover {
	background-color: #f6f6f6;
}

.no-calendar-modal .quantity-selector .quantity {
	background: var(--resaltado);
	border-radius: 20px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	padding: 0.5em 1em;
	margin: 0 1em;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-calendar-modal .controls {
	padding: 0 2em 2em 2em;
	display: flex;
	justify-content: center;
}

.no-calendar-modal .controls .confirm {
	width: 300px;
	justify-content: space-between;
	display: flex;
	padding: 24px;
	margin-left: 70px;
}

.slider-ficha-evento {
    opacity: 1;
    max-width: 1140px;
    margin: 0 auto;
}

.slider-ficha-evento h1 {
    font-size: 55px;
}
.slider-ficha-evento.slider .slides .slide .slide-inner {
    max-width: 1080px;
}

.slider-ficha-evento.slider .thumbnails {
    display: flex;
}

.event-dates-responsive {
    display: none;
}

.subscriptions-enabled .evento {
    padding-bottom: 100px;
}

.subscriptions-enabled .evento .no-calendar {
    position: absolute;
    bottom: 10px;
    top: unset;
    font-size: 12px;
    padding: 2px 10px;
    right: 12px;
    color: white;
    background: var(--resaltado);
    display: inline-block;
    border-radius: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.subscriptions-enabled .evento .remaining-visits {
    position: absolute;
    bottom: 10px;
    top: unset;
    font-size: 12px;
    padding: 2px 10px;
    left: 12px;
    color: white;
    background: var(--resaltado);
    display: inline-block;
    border-radius: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .evento.container {
        padding: 0 0 40px 0;
    }

    .evento .row {
        margin-left: 0;
        margin-right: 0;
    }

    .event-cover {
        height: 150px;
        margin-bottom: 25px;
    }

    .event-info {
        margin-bottom: 80px;
        border-bottom: none;
    }

    .event-info h1 {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        margin-left: 15px;
    }

    .event-info h2 {
        font-size: 12px;
        letter-spacing: 0;
    }

    .event-address {
        font-size: 13px;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
    }

    .event-address .address {
        font-size: 14px;
    }

    .event-description {
        font-size: 15px;
        line-height: 20px;
    }

    .event-description .event-text {
        margin-left: 15px;
        margin-right: 15px;
    }

    .evento .facts > div {
        display: grid;
        grid-template-columns: calc(50px + 1.5em) 2fr;
    }

    .booking-widget.margin-left {
        position: fixed;
        top: unset;
        bottom: 0;
    }

    .booking-widget {
        margin-left: 0;
        width: 100%;
        position: fixed;
        top: auto;
        bottom: 0;
        margin: 0;
        padding: 18px;
        left: 0;
        right: 0;
        border: none;
        border-top: 1px solid lightgrey;
        border-radius: 0;
        background: white;
        z-index: 1;
    }

    .booking-widget.horizontal {
        padding: 1rem;
        top: 60px;
        padding-top: 0;
        overflow-y: auto;
        padding-bottom: 100px;
    }

    .booking-widget .pax-selector,
    .booking-widget .pax-selector,
    .booking-widget .total-price-label {
        width: calc(100% - 30px);
        font-size: 14px;
    }

    .booking-widget.horizontal .selectors > div {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .booking-widget .pax-selector .tock-icon,
    .booking-widget .pax-selector .tock-icon {
        font-size: 25px;
    }

    .booking-widget .pax-button {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .booking-widget.horizontal .selectors > div:first-child,
    .booking-widget.horizontal .selectors > div:last-child {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        border-right: 1px solid #d3d3d8;
    }

    .booking-widget.horizontal .selected-date {
        display: none;
    }

    body .modal-inner .calendar-container {
        padding-top: 10px;
    }

    .related-events-h3 {
        margin-left: 15px;
    }

    .related-events {
        overflow-x: scroll;
        margin-bottom: 0;
        scrollbar-width: 0;
    }

    .related-events::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

    .related-events .horizontal-scroll-container > div {
        width: calc(100vw - 75px);
        display: inline-block;
        margin-right: 15px;
        box-shadow: 0px 4px 8px 0px rgba(27, 28, 36, 0.08);
        filter: grayscale(1);
        transition: filter 300ms;
        background: white;
        padding: 10px;
        border-radius: 20px;
    }

    .related-events .horizontal-scroll-container > div:hover {
        filter: grayscale(0);
    }

    .related-events .horizontal-scroll-container > div:last-child {
        margin-right: 0;
    }

    .related-events .event-image {
        filter: none;
    }

    .related-events-h3 {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .event-info.col-md-8 {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
    }

    body.scroll-lock .container.evento {
        overflow: hidden;
    }

    .event-info .tags {
        margin: 15px 0;
    }

    .slider-ficha-evento {
        margin: 0 0 15px;
    }

    .slider.slider-ficha-evento .slide h1 {
        margin-top: 0;
    }

    .slider.slider-ficha-evento .slide h2 {
        margin-top: 1em;
        margin-bottom: 2em;
    }

    .event-info .tags {
        margin: 10px 0;
    }

    .event-description.iconos {
        padding-top: 0;
    }

    .event-description, .event-menu {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .event-description .event-text.event-info {
        padding-bottom: 15px;
    }

    .event-description .icon-container {
        display: flex;
    }

    .evento .info-negocio {
        padding-left: 15px;
        padding-right: 15px;
    }

    .col-dates {
        display: none;
    }

    .event-dates-responsive {
        margin-bottom: 15px;
        padding-top: 20px;
        display: block;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        border-bottom: 1px solid lightgrey;
    }

    .event-dates-responsive::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .event-dates-responsive .container {
        display: flex;
        align-items: flex-start;
        padding: 0;
        margin: 0;
    }

    .event-dates-responsive .date {
        margin-right: 15px;
        margin-left: 15px;
    }

    .evento .event-dates-responsive .mas-info {
        width: calc(100vw - 75px);
    }

    .event-cover {
        height: 375px;
        margin-bottom: 15px;
    }

    .experiencias {
        border-top: none;
        padding-top: 0;
    }

    .event-description, .event-menu {
        padding-bottom: 20px;
		padding-top: 15px;
    }

	.evento .facts {
		padding-bottom: 0;
        margin: 0 15px;
	}

    .modal-calendario .date-display {
        display: none;
    }

    .evento + .modal-outer .modal-calendario .top-bar {
        flex-direction: column;
        align-items: stretch;
    }

    .evento + .modal-outer .modal-calendario .selection-length-selector {
        border-left: 1px solid lightgrey;
        margin-top: 5px;
    }

    .modal-calendario .pax-button {
        margin-top: 0;
    }

    .modal-calendario .selection-length-button {
        margin-left: 0.5em;
    }

    .modal-calendario .selection-length-selector, .modal-calendario .pax-selector, .modal-calendario .date-display {
        border-radius: 3px;
        min-height: 56px;
        height: 56px;
    }

    .evento .experiencias .date .event-info-description {
        font-size: 15px;
    }
    .evento .experiencias {
        width: 100%;
        padding-bottom: 20px;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: 0;
    }
    
    .evento .experiencias::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }
    
    .evento .experiencias .inner {
        display: flex;
    }
    
    .evento .experiencias .date {
        width: calc(100vw - 89px);
        margin-right: 13px;
        margin-bottom: 0;
    }
    
    .evento .experiencias .date:last-child {
        margin-right: 0;
    }

	.evento .encuestas-clientes .inner {
		overflow-x: auto;
		scrollbar-width: 0;
	}
    
    .evento .encuestas-clientes .inner::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

	.evento .encuestas-clientes .columna {
		display: flex;
		align-items: center;
	}

	.evento .encuestas-clientes .category {
		width: 60vw;
	}

    .evento .encuestas-clientes .category:first-child {
        margin-left: 15px;
    }

	.evento .encuestas-clientes h3 {
		font-size: 20px;
		line-height: 20px;
		margin-bottom: 15px;
        margin-left: 15px;
	}

	.evento .encuestas-clientes .star-meter span {
		font-size: 2em;
	}

	.evento .facts > div:last-child {
		margin-bottom: 1em;
	}

    .no-calendar-modal {
		padding-bottom: 60px;
	}

	.no-calendar-modal .description p:last-child {
		margin-bottom: 0;
	}

	.no-calendar-modal .quantity-selector {
		padding: 0;
		margin-top: 1em;
	}

	.no-calendar-modal .button-confirm-container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		padding: 15px;
	}

	.no-calendar-modal .controls .confirm {
		width: 100%;
		padding: 0 2em;
		margin: 0;
	}

	.no-calendar-modal .controls .confirm span {
		margin-left: auto;
	}
}
